.body {
    margin: 0;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin: auto;
    background-color: #282c34;
}

header {
    box-sizing: border-box;
}

.header-image,
.side-image {
    max-height: 3rem;
}

.pageContainer {
    display: flex;
    padding: 1rem;
    flex: 1;
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.environment-image {
    max-width: 2rem;
    display: 'inline-block';
}

.Loading-screen {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
/* Add padding and styling to Loading Indicator component */

.Loading-description-box {
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
}
.Loading-description-darkmode {
    color: white;
}
.Loading-circular-progress {
    text-align: center;
    padding-bottom: 50px;
}
/* Animation for Loading icon */
.Loading-rotate-icon {
    animation-name: Loading-indicator-spin;
    animation-duration: 3000ms;
    animation-iteration-count: 10;
    /* animation-timing-function: linear;  */
}
@keyframes Loading-indicator-spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
